/*-------------------------------RESPONSIVE DESIGN-------------------------------------*/

@blackgrey: #1b1b1b;
@darkgrey: #959595;
@lightgrey: #d2d2d2;
@yellow-green: #fffb31;
@darkgrey-footer: #484748;


/*@imagePath: ~"http://www.redesign.doemges.ag/templates/doemges-ag/images";*/
@fontPath: "fonts";
@imagePath: "images";

@standard-font-family: 'Fira Sans';

.regular-font {
	font-family: @standard-font-family;
	font-weight: 400;
}

.semi-bold-font {
	font-family: @standard-font-family;
	font-weight: 600;
}

.bold-font {
	font-family: @standard-font-family;
	font-weight: 700;
}

.light-font {
	font-family: @standard-font-family;
	font-weight: 300;
}

.font-size(@sizeValue) {
  @remValue: @sizeValue;
  @pxValue: (@sizeValue * 16);
  font-size: ~"@{pxValue}px"; 
  font-size: ~"@{remValue}rem";
}

.line-height(@sizeValue) {
  @remValue: @sizeValue;
  @pxValue: (@sizeValue * 16);
  line-height: ~"@{pxValue}px"; 
  line-height: ~"@{remValue}rem";
}

@fontPath: "fonts";
@imagePath: "images";

@media all and (max-width: 1000px) {

	#headercontent, #maincontent, #submenuecontent {
		max-width: none;
		width: 96%;
		padding-left: 2%;
		padding-right: 2%;		
	}

	#header {
		#mainnav {
			margin-right: 20px;
		}
	}
	
	#mitarbeit-header {
		width: 100%;
	}

	#footer {
	
		#footercontent {
			max-width: 90%;
			
			#mainnav,#footernav {
				li a {
					.font-size(0.85);
				}
			}
		}
		
	}

}

@media all and (max-width: 1000px) {
	
	.project-slideshow-img {
		width: 100%;
	}

}

@media screen and (min-width: 960px) {
    html {
        margin-left: calc(100vw - 100%);
        margin-right: 0;
    }
}


@media all and (max-width: 950px) {
	#standorte #maincontent {
		.row {
			.col-left, .col-right {
				float: none;
				margin-bottom: 15px;				
			}
			
			.col-right {
				margin-left: 0;
			}
		}
	}
	
	#projektentwicklung, #architektur, #gutachten, 
	#akademie, #geomantie, #impressum {
	
		.col-left, .col-right {
			float: none;
			width: 100%;
			margin-bottom: 20px;
		}
		
		.col-right {
			margin-left: 0;
		}
	}
}


@media all and (max-width: 940px) {
		
	#miteinander-header {
		width: 100%;
	}
	
	.project-list-galery-entry {
		width: 100%;
		float: none;
		margin-bottom: 20px;
		
		img {
			width: 100%;
		}
	}	
	
	div.project-desc {
	
		padding-left: 0;
	
		div.project-desc-left {
			width: 100%;
			float: none;
			margin-right: 0;
			padding-left: 0;		
		}
		
		div.project-desc-right {
			width: 100%;
			margin-left: 0;
			
			.project-navi {
				width: 60%;
				margin: auto;
			}			
			.project-description,.project-addon {
				width: 100%;
			}
			
			.project-description {
				margin-top: 40px;
				margin-bottom: 40px;
			}
		}
		
	}
}

@media all and (max-width: 790px) {
	
	#geschaeftsfuehrung .geschaeftsfuehrung_details img {
		float: none;
	}
	
	#footer {
	
		#footercontent {
			max-width: 95%;
			
			#mainnav,#footernav {
				li a {
				.regular-font;			
				}
			}
		}
		
	}

}

@media all and (max-width: 750px) {
	#footer {
	
		min-height: 100px;
	
		#mainnav {
			display: none;
		}
		#footernav {
			width: 100%;
			
			li {
				float: left;
				margin-right: 5%;
			}
		}
	}
}


@media all and (max-width: 650px) {
	body.overlay-on { 
		
		#logo {
			a:link, a:active, a:visited, a:hover {
				background: url('@{imagePath}/bha_logo.png') no-repeat 0; 	
			}
		}
		
		
		
		#header,#headercontent {
			z-index: auto;
		}
		
		#mainnav {
			position: absolute;
			z-index: 9000;
		}		
	}
	
	#miteinander {
		
		.miteinander-galery-right, .miteinander-galery-left {
    	width: 100%;
    	float: none;
    	margin-right: 0;
    }
    
    .miteinander-galery-right {
    	margin-left: 0;
    }
	}
	
	#mitarbeit {
	
		.mitarbeit-galery-left, .mitarbeit-galery-right {
			width: 100%;
			float: none;
			margin-left: 0;
		}
		
		.category-desc {
			width: 100%;
			margin-left: 0;
		}
		
	}
		
}

@media all and (max-width: 500px) {
	
	#miteinander #header-slogan {
		.font-size(1.5);
		padding-left: 2%;
	}
	
}

@media all and (max-width: 480px) {

	#projektentwicklung, #architektur, #gutachten, 
	#akademie, #geomantie {
	
		.col-left img {
			width: 100%;
		}
		
	}	
}

@media all and (max-height: 811px) {
	
	#team #main{
		margin-bottom: 20px;
}

}

@media all and (max-height: 504px) {
	.nav-overlay {
			 
		.pos-center {
			margin-bottom: 10%;
			overflow: scroll; 
		}
		
		#mainnav-overlay {
			overflow: scroll;
		}
	}
}

